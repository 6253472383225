import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Downloads = () => (
  <Layout>
    <SEO title="Downloads" />
    <div className="container">
      <table class="table table-striped table-hover table-bordered border-light rounded mt-5">
        <thead>
          <tr>
            <th scope="col" colSpan="4" className="text-center table-dark">
              Εργαλεία απομακρυσμένης πρόσβασης
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td colSpan="2">TeamViewer</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://dl.teamviewer.com/download/version_15x/TeamViewer_Setup.exe"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td colSpan="2">AnyDesk</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://download.anydesk.com/AnyDesk.exe"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td colSpan="2">Ammy</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://www.ammyy.com/AA_v3.php?v=10147351"
              >
                Λήψη
              </a>
            </td>
          </tr>
        </tbody>

        <thead>
          <tr>
            <th scope="col" colSpan="4" className="text-center table-dark">
              Λογισμικό antivirus
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td colSpan="2">Panda Cloud Security</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://www.pandacloudsecurity.com/"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td colSpan="2">MalwareBytes Free</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://data-cdn.mbamupdates.com/web/mb4-setup-consumer/MBSetup.exe"
              >
                Λήψη
              </a>
            </td>
          </tr>
        </tbody>

        <thead>
          <tr>
            <th scope="col" colSpan="4" className="text-center table-dark">
              Βοηθητικά προγράμματα
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td colSpan="2">Google Chrome</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://www.google.com/intl/el/chrome/"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td colSpan="2">Microsoft Edge</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://www.microsoft.com/el-gr/edge"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td colSpan="2">Firefox</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://www.mozilla.org/el/firefox/new/"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">4</th>
            <td colSpan="2">7-Zip</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://www.7-zip.org/a/7z1900-x64.exe"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">5</th>
            <td colSpan="2">WinRAR</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://www.win-rar.com/fileadmin/winrar-versions/winrar/winrar-x64-591.exe"
              >
                Λήψη
              </a>
            </td>
          </tr>
        </tbody>

        <thead>
          <tr>
            <th scope="col" colSpan="4" className="text-center table-dark">
              SQL
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td colSpan="2">Microsoft® SQL Server® Express Advanced 2019</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://download.microsoft.com/download/7/c/1/7c14e92e-bdcb-4f89-b7cf-93543e7112d1/SQLEXPRADV_x64_ENU.exe"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td colSpan="2">Microsoft® SQL Server® Express Advanced 2017</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://download.microsoft.com/download/E/F/2/EF23C21D-7860-4F05-88CE-39AA114B014B/SQLEXPRADV_x64_ENU.exe"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td colSpan="2">
              Microsoft® SQL Server® Express Advanced 2016 SP2
            </td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://download.microsoft.com/download/4/1/A/41AD6EDE-9794-44E3-B3D5-A1AF62CD7A6F/sql16_sp2_dlc/en-us/SQLEXPRADV_x64_ENU.exe"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">4</th>
            <td colSpan="2">Microsoft® SQL Server® Express Advanced 2014</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://download.microsoft.com/download/E/A/E/EAE6F7FC-767A-4038-A954-49B8B05D04EB/ExpressAdv%2064BIT/SQLEXPRADV_x64_ENU.exe"
              >
                Λήψη
              </a>
            </td>
          </tr>
        </tbody>

        <thead>
          <tr>
            <th scope="col" colSpan="4" className="text-center table-dark">
              Εργαλεία Office
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td colSpan="2">LibreOffice ( Δωρεάν σουΐτα γραφείου )</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://el.libreoffice.org/download/"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td colSpan="2">Adobe Acrobat Reader DC</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://get2.adobe.com/reader/"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td colSpan="2">Sumatra PDF</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://www.sumatrapdfreader.org/dl2/SumatraPDF-3.2-64-install.exe"
              >
                Λήψη
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">3</th>
            <td colSpan="2">Bullzip PDF Printer</td>
            <td>
              <a
                className="btn btn-sm btn-outline-secondary"
                href="https://www.bullzip.com/products/pdf/download.php"
              >
                Λήψη
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
)

export default Downloads
